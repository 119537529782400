import FancyBearsToMigrateContainer from '../containers/FancyBearsToMigrateContainer';
import MigratedFancyBearsContainer from '../containers/MigratedFancyBearsContainer';
import Panel from '../components/Panel';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import {
  Box,
  Container,
  Heading,
  Text
  } from '@chakra-ui/react';

const ConnectedPage = () => {
  const translate = useTranslate();
  const { fancyBears } = useWalletAssets();

  return (
    <Container pt="12" pb="16">
      <Panel>
        <Box mb="4" pl="2" mt="1.5">
          <Heading fontSize="2xl">
            {translate('fancyBearsToMigrate:title')}
          </Heading>

          <Text fontSize="sm" mt="1" opacity="0.8">
            {translate('fancyBearsToMigrate:description')}
          </Text>
        </Box>

        <FancyBearsToMigrateContainer />
      </Panel>

      <Panel mt="12" bg="primary.400" color="dark.800">
        <Box mb="4" px="2" mt="1.5">
          <Heading fontSize="2xl">
            {translate('migratedFancyBears:title')}
          </Heading>

          <Text fontSize="sm" mt="1" opacity="0.8">
            {translate('migratedFancyBears:description', {
              fancyBearsCount: fancyBears?.length,
            })}
          </Text>
        </Box>

        <MigratedFancyBearsContainer />
      </Panel>
    </Container>
  );
};

export default ConnectedPage;
