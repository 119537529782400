import { useEffect } from 'react';
import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';

import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';

const useMigrateFancyBear = (
  tokenId?: number,
  onSuccess?: () => void,
  onError?: (error: any) => void
): [(args: any) => void, boolean] => {
  const { config: writeConfig, error: prepareError } = usePrepareContractWrite({
    addressOrName: config.contracts.fancyBearsMigrationContract.address,
    contractInterface: config.contracts.fancyBearsMigrationContract.interface,
    functionName: 'migrateBear',
    args: [tokenId],
    enabled: !!tokenId,
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, onSuccess]);

  useEffect(() => {
    if (!isLoading && prepareError && onError) {
      onError(prepareError);
    }
  }, [isLoading, prepareError, onError]);

  return [write!, isLoading || isWaitLoading];
};

export default useMigrateFancyBear;
