import { chain } from 'wagmi';
import fancyBearsABI from '../abis/fancyBears.abi.json';
import fancyBearsMigrationABI from '../abis/fancyBearsMigration.abi.json';
import oldFancyBearsABI from '../abis/oldFancyBears.abi.json';
import traitStakingABI from '../abis/traitStaking.abi.json';
import traitSwapABI from '../abis/traitSwap.abi.json';
import sepolia from './chains/sepolia';

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as 'production' | 'development',
  isTestnet,

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  infura: {
    infuraId: process.env.REACT_APP_INFURA_ID!,
  },

  quicknode: {
    rpcUrl: process.env.REACT_APP_QUICKNODE_RPC_URL!,
  },

  chains: isTestnet ? [sepolia] : [chain.mainnet],

  contracts: {
    oldFancyBearsContract: {
      address: process.env.REACT_APP_OLD_FANCY_BEARS_CONTRACT_ADDRESS!,
      interface: oldFancyBearsABI,
    },
    fancyBearsContract: {
      address: process.env.REACT_APP_FANCY_BEARS_CONTRACT_ADDRESS!,
      interface: fancyBearsABI,
    },
    fancyBearsMigrationContract: {
      address: process.env.REACT_APP_FANCY_BEARS_MIGRATION_CONTRACT_ADDRESS!,
      interface: fancyBearsMigrationABI,
    },
    traitStakingContract: {
      address: process.env.REACT_APP_TRAITS_STAKING_CONTRACT_ADDRESS!,
      interface: traitStakingABI,
    },
    traitSwapContract: {
      address: process.env.REACT_APP_TRAITSWAP_CONTRACT_ADDRESS!,
      interface: traitSwapABI,
    },
  },

  urls: {
    fancyBearsImagesUrl: process.env.REACT_APP_FANCY_BEAR_IMAGES_URL!,
    fancyBearsCollectionUrl: process.env.REACT_APP_FANCY_BEARS_COLLECTION_URL!,
    oldFancyBearsImagesUrl: process.env.REACT_APP_OLD_FANCY_BEAR_IMAGES_URL!,
    oldFancyBearsCollectionUrl:
      process.env.REACT_APP_OLD_FANCY_BEARS_COLLECTION_URL!,
    openSeaAssetsUrl: process.env.REACT_APP_OPENSEA_ASSET_BASE_URL!,
    publicUrl: process.env.PUBLIC_URL!,
    apiUrl: process.env.REACT_APP_API_URL!,
    buyEthUrl: process.env.REACT_APP_BUY_ETH_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
