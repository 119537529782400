import BoldSpinner from './BoldSpinner';
import FancyBearImage from './FancyBearImage';
import ImageSize from '../types/imageSize';
import LevelBadge from './LevelBadge';
import SelectableCard, { SelectableCardProps } from './SelectableCard';
import { Box, Center } from '@chakra-ui/react';

export type FancyBearCardProps = SelectableCardProps & {
  tokenId: number;
  imageSize?: ImageSize;
  level?: number;
  isBusy?: boolean;
  isOld?: boolean;
  onClick?: (tokenId: number) => void;
};

const FancyBearCard: React.FC<FancyBearCardProps> = ({
  tokenId,
  imageSize,
  isDisabled,
  isBusy,
  isOld = false,
  level,
  children,
  onClick,
  ...rest
}) => (
  <SelectableCard
    isDisabled={isDisabled}
    onClick={onClick ? () => onClick?.(tokenId) : undefined}
    {...rest}
  >
    <Box position="relative">
      <FancyBearImage
        size={imageSize}
        tokenId={tokenId}
        isDisabled={isDisabled || isBusy}
        isOld={isOld}
      />

      {level !== undefined && (
        <LevelBadge level={level} position="absolute" top="2" right="2" />
      )}

      <Center
        position="absolute"
        top="-2"
        right="-2"
        bg="bright.500"
        w="10"
        h="10"
        zIndex="dropdown"
        opacity={isBusy ? '1' : '0'}
        transition="opacity 0.15s"
      >
        {isBusy && <BoldSpinner size="sm" thickness="3px" />}
      </Center>
    </Box>

    <Box>{children}</Box>
  </SelectableCard>
);

export default FancyBearCard;
