const translations = {
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:free': 'Free',
  'common:claim': 'Claim',
  'common:buy': 'Buy',
  'common:refresh': 'Refresh',
  'common:cancel': 'Cancel',
  'common:noResults': 'No results…',
  'common:fancyBear': 'Fancy Bear',
  'common:fancyBears': 'Fancy Bears',
  'common:level': 'Level',
  'common:waitMessage': 'Please wait a moment for the metadata to be updated…',
  'common:tokenId': 'Token ID',
  'common:honey': '$HONEY',
  'common:view': 'View',
  'common:migrate': 'Migrate',
  'common:approve': 'Approve',

  'error:default': 'Oops! Something went wrong.',

  'success:default': 'Success! Thank you.',

  'fancyBearsToMigrate:title': 'Migrate Your Fancy Bears',
  'fancyBearsToMigrate:description':
    'Approve the migration contract and choose Fancy Bears to migrate.',
  'fancyBearsToMigrate:emptyCard:label': 'Buy Fancy Bear',
  'fancyBearsToMigrate:approveButton:label': 'Approve the Migration Contract',

  'migratedFancyBears:title': 'Your Migrated Fancy Bears',
  'migratedFancyBears:description':
    'These are your Fancy Bears migrated to the new contract.',
  'migratedFancyBears:emptyCard:label': 'Buy Fancy Bear',

  'connect:title': 'Connect Your Wallet',
  'connect:appTitle': 'Migrate Your Fancy Bears',
  'connect:appDescription': 'Migrate your Fancy Bears to the new contract.',
  'connect:button:label': 'Connect with {connector}',

  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',
  'footer:contract': 'Contract',

  'walletPopup:title': 'Your Wallet',

  'migratePopup:title': 'Migrate #{tokenId} to the New Contract',
  'migratePopup:button': 'Migrate',
  'migratePopup:unstakeTraitsFirst':
    'It seems that #{tokenId} still has staked traits. Before migrating it to the new contract, please unstake all traits in the TraitSwap app.',
  'migratePopup:unstakeTraitsFirstButton': 'TraitSwap',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',
};

export default translations;
