import { Chain } from 'wagmi';

const sepolia = {
  id: 11_155_111,
  network: 'sepolia',
  name: 'Sepolia',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'SEP', decimals: 18 },
  rpcUrls: {
    alchemy: 'https://eth-sepolia.g.alchemy.com/v2',
    infura: 'https://sepolia.infura.io/v3',
    default: 'https://rpc.sepolia.org',
    public: 'https://rpc.sepolia.org',
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
    },
  },
  multicall: {
    address: '0xca11bde05977b3631167028862be2a173976ca11',
    blockCreated: 6507670,
  },
  testnet: true,
} as const as Chain;

export default sepolia;
