import config from '../constants/config';
import { useContractRead } from 'wagmi';
import { useEffect, useMemo, useState } from 'react';

const useOldFancyBearsInWallet = (
  walletAddress?: string
): [tokenIds: number[] | undefined, readFancyBearsInWallet: () => void] => {
  const [tokensInWallet, setNftsInWallet] = useState<number[]>();

  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.oldFancyBearsContract.address,
    contractInterface: config.contracts.oldFancyBearsContract.interface,
    functionName: 'tokensInWallet',
    args: [walletAddress],
  });

  useEffect(() => {
    if (data) {
      setNftsInWallet(data?.map(bn => bn.toNumber() as number));
    }
  }, [data]);

  return useMemo(() => [tokensInWallet, refetch], [tokensInWallet, refetch]);
};

export default useOldFancyBearsInWallet;
