import CoinbaseWalletIcon from '../components/CoinbaseWalletIcon';
import config from '../constants/config';
import MetaMaskIcon from '../components/MetaMaskIcon';
import Panel from '../components/Panel';
import usePageTracking from '../hooks/usePageTracking';
import useSnackbarRpcError from '../hooks/useSnackbarRpcError';
import useTranslate from '../hooks/useTranslate';
import WalletConnectIcon from '../components/WalletConnectIcon';
import { useConnect } from 'wagmi';
import {
  Button,
  Center,
  Container,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

const connectorIcons: Record<string, React.FC> = {
  MetaMask: MetaMaskIcon,
  WalletConnect: WalletConnectIcon,
  'Coinbase Wallet': CoinbaseWalletIcon,
};

const ConnectPage = () => {
  const translate = useTranslate();
  const { connectors, connect, error } = useConnect();

  usePageTracking();
  useSnackbarRpcError(error);

  return (
    <>
      <Container
        display="flex"
        pt="20"
        pb="20"
        maxW="md"
        flex="1"
        alignItems="center"
        h="full"
      >
        <VStack w="full" align="center" spacing="8">
          <Image
            src={`${config.urls.publicUrl}/connect-logo.svg`}
            w="40"
            h="auto"
          />

          <Center flexDir="column">
            <Image
              src={`${config.urls.publicUrl}/connect-illustration.png`}
              w="full"
              maxW="90%"
            />
          </Center>

          <VStack textAlign="center" mb="6" mt="1" color="dark.900">
            <Heading fontSize="3xl" w="full" textAlign="center">
              {translate('connect:appTitle')}
            </Heading>

            <Text opacity="0.8" fontWeight="semibold">
              {translate('connect:appDescription')}
            </Text>
          </VStack>

          <Panel p="8" bg="dark.800">
            <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
              {translate('connect:title')}
            </Heading>

            <VStack w="full" align="stretch" spacing="3">
              {connectors.map(connector => (
                <Button
                  key={connector.id}
                  onClick={() => connect({ connector })}
                  isDisabled={!connector.ready}
                  size="lg"
                  w="full"
                  iconSpacing="3"
                  variant="outline"
                  colorScheme="primary"
                  borderRadius="full"
                  leftIcon={
                    <Icon as={connectorIcons[connector.name]} w="8" h="8" />
                  }
                >
                  {translate('connect:button:label', {
                    connector: connector.name,
                  })}
                </Button>
              ))}
            </VStack>
          </Panel>
        </VStack>
      </Container>
    </>
  );
};

export default ConnectPage;
