import { Button, Icon, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { useCallback } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EmptyFancyBearCard from '../components/EmptyFancyBearCard';
import FancyBearCard from '../components/FancyBearCard';
import config from '../constants/config';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';

const MigratedFancyBearsContainer = () => {
  const translate = useTranslate();
  const { fancyBears } = useWalletAssets();

  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
  });

  const renderCard = useCallback(
    (tokenId: number) => {
      const externalUrl = `${config.urls.openSeaAssetsUrl}/${config.contracts.fancyBearsContract.address}/${tokenId}`;

      return (
        <FancyBearCard
          key={tokenId}
          tokenId={tokenId}
          imageSize="256"
          bg="primary.200"
          color="dark.900"
        >
          <Button
            mt="2"
            colorScheme="primary"
            as="a"
            w="full"
            size={buttonSize}
            href={externalUrl}
            target="_blank"
            rel="noreferrer"
            cursor="pointer"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
          >
            {translate('common:view')} #{tokenId}
          </Button>
        </FancyBearCard>
      );
    },
    [translate, buttonSize]
  );

  return (
    <>
      <SimpleGrid
        w="full"
        columns={{
          base: 2,
          sm: 3,
        }}
        gap="2"
      >
        {fancyBears?.map(renderCard)}

        <EmptyFancyBearCard
          position="relative"
          bg="primary.200"
          color="dark.800"
        >
          <Button
            mt="2"
            colorScheme="primary"
            as="a"
            w="full"
            href={config.urls.fancyBearsCollectionUrl}
            target="_blank"
            rel="noreferrer"
            size={buttonSize}
            cursor="pointer"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
          >
            {translate('fancyBearsToMigrate:emptyCard:label')}
          </Button>
        </EmptyFancyBearCard>
      </SimpleGrid>
    </>
  );
};

export default MigratedFancyBearsContainer;
