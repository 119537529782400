import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';
import { useEffect } from 'react';

import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';

const useApproveOldFancyBears = (
  onSuccess?: () => void
): [() => void, boolean] => {
  const { config: writeConfig } = usePrepareContractWrite({
    addressOrName: config.contracts.oldFancyBearsContract.address,
    contractInterface: config.contracts.oldFancyBearsContract.interface,
    functionName: 'setApprovalForAll',
    args: [config.contracts.fancyBearsMigrationContract.address, true],
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, onSuccess]);

  return [write!, isLoading || isWaitLoading];
};

export default useApproveOldFancyBears;
