import config from '../constants/config';
import { useContractRead } from 'wagmi';
import { useMemo } from 'react';

const useIsOldFancyBearsApproved = (
  walletAddress?: string
): [boolean | undefined, () => void] => {
  const args = useMemo(
    () =>
      walletAddress && [
        walletAddress,
        config.contracts.fancyBearsMigrationContract.address,
      ],
    [walletAddress]
  );

  const { data: isApproved, refetch: readAllowance } = useContractRead({
    addressOrName: config.contracts.oldFancyBearsContract.address,
    contractInterface: config.contracts.oldFancyBearsContract.interface,
    functionName: 'isApprovedForAll',
    args,
  });

  return [isApproved as unknown as boolean, readAllowance];
};

export default useIsOldFancyBearsApproved;
