import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { WagmiConfig, configureChains, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import Background from './components/Background';
import ComposedProviders from './components/ComposedProviders';
import Header from './components/Header';
import Layout from './components/Layout';
import config from './constants/config';
import theme from './constants/theme';
import translations from './constants/translations';
import NetworkErrorsContainer from './containers/NetworkErrorsContainer';
import AccountGuard from './guards/AccountGuard';
import ConnectPage from './pages/ConnectPage';
import ConnectedPage from './pages/ConnectedPage';
import TranslationsProvider from './providers/TranslationsProvider';

const quicknodeProvider = jsonRpcProvider({
  rpc: () => ({
    http: config.quicknode.rpcUrl,
  }),
});

const { chains, provider } = configureChains(config.chains, [
  quicknodeProvider,
  publicProvider(),
]);

export const injectedConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    infuraId: config.infura.infuraId,
    qrcode: true,
  },
});

export const coinbaseWalletConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Staking | FBM',
  },
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    injectedConnector,
    walletConnectConnector,
    coinbaseWalletConnector,
  ],
  provider,
});

function App() {
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <WagmiConfig client={wagmiClient}>
        <TranslationsProvider translations={translations}>
          <Background />

          <AccountGuard
            yeyElement={
              <>
                <ComposedProviders>
                  <Layout>
                    <Header />
                    <ConnectedPage />
                  </Layout>
                </ComposedProviders>

                <NetworkErrorsContainer />
              </>
            }
            neyElement={
              <Layout>
                <ConnectPage />
              </Layout>
            }
          />
        </TranslationsProvider>
      </WagmiConfig>
    </ChakraProvider>
  );
}

export default App;
