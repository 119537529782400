import React from 'react';
import { BigNumber } from 'ethers';

export type WalletAssetsContextValue = {
  balance?: BigNumber;
  readBalance: () => void;
  fancyBears?: number[];
  readFancyBears: () => void;
  oldFancyBears?: number[];
  readOldFancyBears: () => void;
};

// @ts-ignore
const WalletAssetsContext = React.createContext<WalletAssetsContextValue>();

WalletAssetsContext.displayName = 'WalletAssetsContext';

export default WalletAssetsContext;
