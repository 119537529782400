import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import config from './constants/config';
import reportWebVitals from './reportWebVitals';

if (config.sentry.sentryDsn) {
  Sentry.init({
    dsn: config.sentry.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: config.environment,
    maxValueLength: 1000,
  });
}

if (config.ga.gaId) {
  ReactGA.initialize(config.ga.gaId);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
