import { Box, Button, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import EmptyFancyBearCardWithLink from '../components/EmptyFancyBearCardWithLink';
import FancyBearCard from '../components/FancyBearCard';
import MigratePopup from '../components/MigratePopup';
import config from '../constants/config';
import useApproveOldFancyBears from '../hooks/useApproveOldFancyBears';
import useIsOldFancyBearsApproved from '../hooks/useIsOldFancyBearsApproved';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';

const FancyBearsToMigrateContainer = () => {
  const translate = useTranslate();
  const { address } = useAccount();
  const { oldFancyBears } = useWalletAssets();

  const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>();

  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
  });

  const [isApproved, readIsApproved] = useIsOldFancyBearsApproved(address);
  const isCheckingApproval = isApproved === undefined;

  const [approveTokens, isApproving] = useApproveOldFancyBears(() => {
    readIsApproved();
  });

  const handleMigrate = useCallback((tokenId: number) => {
    setSelectedTokenId(tokenId);
  }, []);

  const handleMigrateSuccess = useCallback(() => {
    setSelectedTokenId(undefined);
  }, []);

  const handleMigratePopupClose = useCallback(() => {
    setSelectedTokenId(undefined);
  }, []);

  const isMigrating = selectedTokenId !== undefined;

  // useEffect(() => {
  //   if (
  //     selectedTokenId !== undefined &&
  //     isApproved &&
  //     !isMigrating &&
  //     migrateToken
  //   ) {
  //     migrateToken(selectedTokenId);
  //     setSelectedTokenId(undefined);
  //   }
  // }, [selectedTokenId, isApproved, isMigrating, migrateToken]);

  const renderCard = useCallback(
    (tokenId: number) => {
      return (
        <FancyBearCard
          key={tokenId}
          tokenId={tokenId}
          imageSize="256"
          isOld={true}
        >
          <Button
            w="full"
            size={buttonSize}
            colorScheme={
              !isApproved || isCheckingApproval || isMigrating
                ? 'dark'
                : 'primary'
            }
            mt="2"
            onClick={() => handleMigrate(tokenId)}
            isDisabled={!isApproved || isCheckingApproval || isMigrating}
            isLoading={
              isCheckingApproval || (isMigrating && selectedTokenId === tokenId)
            }
          >
            {translate('common:migrate')} #{tokenId}
          </Button>
        </FancyBearCard>
      );
    },
    [
      translate,
      handleMigrate,
      selectedTokenId,
      isApproved,
      isCheckingApproval,
      isMigrating,
      buttonSize,
    ]
  );

  useEffect(() => {
    readIsApproved();
  }, [isApproved, readIsApproved]);

  return (
    <>
      {!isApproved && (
        <Box px="2" mb="4">
          <Button
            flex="none"
            w="full"
            colorScheme={isCheckingApproval || isApproving ? 'dark' : 'primary'}
            isLoading={isCheckingApproval || isApproving}
            onClick={approveTokens}
          >
            {translate('fancyBearsToMigrate:approveButton:label')}
          </Button>
        </Box>
      )}

      <SimpleGrid
        w="full"
        columns={{
          base: 2,
          sm: 3,
        }}
        gap="2"
      >
        {oldFancyBears?.map(renderCard)}

        <EmptyFancyBearCardWithLink
          label={translate('fancyBearsToMigrate:emptyCard:label')}
          externalUrl={config.urls.oldFancyBearsCollectionUrl}
        />
      </SimpleGrid>

      {selectedTokenId && (
        <MigratePopup
          isOpen={isMigrating}
          tokenId={selectedTokenId!}
          onClose={handleMigratePopupClose}
          onSuccess={handleMigrateSuccess}
        />
      )}
    </>
  );
};

export default FancyBearsToMigrateContainer;
