import config from '../constants/config';
import ImageSize from '../types/imageSize';
import { AspectRatio, AspectRatioProps, Image } from '@chakra-ui/react';
import { generateRandomInteger } from '../utils/numberUtils';
import { useMemo } from 'react';

type FancyBearImageProps = Omit<AspectRatioProps, 'ratio'> & {
  tokenId?: number;
  size?: ImageSize;
  isDisabled?: boolean;
  isOld?: boolean;
};

const FancyBearImage = ({
  tokenId,
  size = '512',
  isDisabled = false,
  isOld = false,
  ...rest
}: FancyBearImageProps) => {
  const cacheId = useMemo(() => generateRandomInteger(1, 100), []);

  return (
    <AspectRatio
      ratio={1}
      w="full"
      bg="dark.400"
      borderRadius="md"
      overflow="hidden"
      {...rest}
    >
      <Image
        src={
          tokenId !== undefined
            ? `${
                isOld
                  ? config.urls.oldFancyBearsImagesUrl
                  : config.urls.fancyBearsImagesUrl
              }/${
                size !== 'original' ? `${size}/` : ''
              }${tokenId}.png?v=${cacheId}`
            : undefined
        }
        fit="cover"
        opacity={isDisabled ? 0.5 : 1}
        transition="opacity 0.15s"
      />
    </AspectRatio>
  );
};

export default FancyBearImage;
