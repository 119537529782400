import WalletAssetsProvider from '../providers/WalletAssetsProvider';

type ComposedProvidersProps = {
  children: JSX.Element;
};

const ComposedProviders = ({ children }: ComposedProvidersProps) => {
  return <WalletAssetsProvider>{children}</WalletAssetsProvider>;
};

export default ComposedProviders;
