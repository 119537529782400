import { serializeError } from 'eth-rpc-errors';
import { useEffect } from 'react';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';

function copyError(err: Error, newMessage: string) {
  const newErr = new Error(newMessage);

  newErr.stack = err.stack;

  return newErr;
}

const useSnackbarError = (error?: any) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      const err = copyError(error, error.message);

      snackbar(
        'error',
        serializeError(err).message || translate('error:default')
      );
    }
  }, [error]);
};

export default useSnackbarError;
