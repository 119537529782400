import { Button, Icon, ModalProps, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import useMigrateFancyBear from '../hooks/useMigrateFancyBear';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import FancyBearImage from './FancyBearImage';
import SimplePopup from './SimplePopup';

type MigratePopupProps = Omit<ModalProps, 'children'> & {
  tokenId: number;
  onSuccess?: () => void;
};

const MigratePopup = ({
  tokenId,
  onSuccess,
  onClose,
  ...rest
}: MigratePopupProps) => {
  const translate = useTranslate();

  const { readFancyBears, readOldFancyBears } = useWalletAssets();
  const [hasUnstakedTraits, setHasUnstakedTraits] = useState<
    boolean | undefined
  >(undefined);

  const [migrateToken, isMigrating] = useMigrateFancyBear(
    tokenId,
    () => {
      readFancyBears();
      readOldFancyBears();

      onSuccess?.();
    },
    (error: any) => {
      if (error.message?.includes?.('Unstake traits first!')) {
        setHasUnstakedTraits(true);
      }
    }
  );

  return (
    <SimplePopup
      title={translate('migratePopup:title', {
        tokenId: tokenId.toString(),
      })}
      size="md"
      onClose={onClose}
      {...rest}
    >
      <VStack spacing="4" mt="6">
        {hasUnstakedTraits && (
          <VStack
            textAlign="center"
            spacing="4"
            p="4"
            bg="dark.800"
            borderRadius="md"
          >
            <Text fontSize="sm">
              {translate('migratePopup:unstakeTraitsFirst', {
                tokenId: tokenId.toString(),
              })}
            </Text>

            <Button
              size="sm"
              colorScheme="dark"
              as="a"
              href="https://fancybears.traitswap.com/"
              target="_blank"
              rel="noreferrer"
              cursor="pointer"
              variant="outline"
              rightIcon={<Icon as={FaExternalLinkAlt} />}
              onClick={() => {
                onClose();
              }}
            >
              {translate('migratePopup:unstakeTraitsFirstButton')}
            </Button>
          </VStack>
        )}

        <FancyBearImage tokenId={tokenId} />

        <Button
          isLoading={isMigrating}
          onClick={() => migrateToken([tokenId])}
          colorScheme="primary"
          size="lg"
          width="100%"
          isDisabled={hasUnstakedTraits}
        >
          {translate('migratePopup:button')}
        </Button>
      </VStack>
    </SimplePopup>
  );
};

export default MigratePopup;
